<div class="wrapper-select-field">
<mat-form-field
  appearance="outline"
  class="text-field"
  [ngClass]="class"
  floatLabel="always"
>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    disableRipple
    [value]="selectedValueChange"
    (selectionChange)="onSelectionChange($event)"
    [formControl]="control"
    [disabled]="readOnly"
  >
    @for(option of options; track option){
    <mat-option [value]="option.Text">{{ option.Text }}</mat-option>
    }
  </mat-select>

  @if(timeSelect){
  <span matSuffix>
    <div class="svg_box" [innerHtml]="DatePickerClockIcon"></div>
  </span>
  }@else {
  <span matSuffix>
    <div class="svg_box" [innerHtml]="SelectDropdownDownArrowIcon"></div>
  </span>
  }
</mat-form-field>

@if((control.invalid && (control.touched)) || formsubmitted){
<mat-error>
  @if(control.errors?.['required']){
  <ng-container> {{ label }} is required. </ng-container>
  }
</mat-error>
}
</div>
